<template>
  <div class="safety-page">
    <div class="header">
      <span class="title">消防安全等级</span>
      <div class="update-text">每7天更新一次</div>
    </div>

    <div class="safety-card" :class="'level-' + level">
      <div class="level-section">
        <div class="level-badge">{{ level }}</div>
        <div class="level-text">安全等级</div>
      </div>
      
      <div class="info-section">
        <div class="cinema-name">{{ cinemaName }}</div>
        <div class="divider"></div>
        <div class="source">自省消防支队</div>
      </div>
    </div>

    <div class="score-grid">
      <div class="score-item">
        <div class="score-label">城市评分</div>
        <div class="score-value">{{ cityScore }}</div>
      </div>
      
      <div class="score-item">
        <div class="score-label">影院消防信用评分</div>
        <div class="score-value">{{ DistrictScore }}</div>
      </div>
    </div>

    <div class="description">
      <van-icon name="info-o" />
      <div class="desc-text">
        消防安全等级按照A、B、C、D、E五个等级来进行划分分别对应五种不同颜色：
        <div class="level-list">
          <span class="level-tag level-A">A 优秀</span>
          <span class="level-tag level-B">B 良好</span>
          <span class="level-tag level-C">C 一般</span>
          <span class="level-tag level-D">D 不好</span>
          <span class="level-tag level-E">E 严重</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
import { Toast } from 'vant'

export default {
  name: 'FireSafety',
  data() {
    return {
      cinemaId: '',
      cinemaName: '',
      level: 'A',
      city: '嘉兴市',
      cityScore: '99',
      DistrictScore: '',
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    const cinema = this.$store.state.selectedCinema || {}
    this.cinemaName = cinema.CinemaName
    this.getData()
  },
  methods: {
    getData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = {
        UserCode: userCode,
        CmdIndex: "101606",
        cmdArgs: [this.cinemaId]
      }
      zjApi.getPs(query).then((r) => {
        const { result: data, code, Message } = r
        if (code !== 200) {
          Toast(Message)
        } else {
          this.level = data.XfCinemaDj || '-'
          this.cityScore = data.XfCityScore || '-'
          this.DistrictScore = data.XfDistrictScore || '-'
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.safety-page {
  min-height: 100vh;
  background: #f5f7fa;
  padding: 16px;
}

.header {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  padding: 20px;
  border-radius: 12px;
  color: #fff;
  margin-bottom: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

  .title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
  }

  .update-text {
    font-size: 13px;
    opacity: 0.8;
  }
}

.safety-card {
  display: flex;
  padding: 24px;
  border-radius: 12px;
  color: #fff;
  margin-bottom: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background: #4facfe;

  &.level-A {
    background: linear-gradient(135deg, #1890ff 0%, #36cfc9 100%);
  }
  &.level-B {
    background: linear-gradient(135deg, #52c41a 0%, #73d13d 100%);
  }
  &.level-C {
    background: linear-gradient(135deg, #faad14 0%, #ffc53d 100%);
  }
  &.level-D {
    background: linear-gradient(135deg, #ff4d4f 0%, #ff7875 100%);
  }
  &.level-E {
    background: linear-gradient(135deg, #000000 0%, #434343 100%);
  }

  .level-section {
    flex: 1;
    text-align: center;
    padding-right: 24px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);

    .level-badge {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 8px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .level-text {
      font-size: 14px;
      opacity: 0.9;
    }
  }

  .info-section {
    flex: 4;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .cinema-name {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    .divider {
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      margin: 12px 0;
    }

    .source {
      text-align: right;
      font-size: 14px;
      opacity: 0.9;
    }
  }
}

.score-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;

  .score-item {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

    .score-label {
      font-size: 14px;
      color: #666;
      margin-bottom: 8px;
    }

    .score-value {
      font-size: 24px;
      font-weight: 600;
      color: #1890ff;
    }
  }
}

.description {
  background: #fff;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);

  .van-icon {
    font-size: 16px;
    color: #999;
    margin-top: 2px;
  }

  .desc-text {
    color: #666;
    font-size: 14px;
    line-height: 1.6;

    .level-list {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 12px;
    }

    .level-tag {
      padding: 4px 12px;
      border-radius: 4px;
      font-size: 13px;
      
      &.level-A { background: rgba(24, 144, 255, 0.1); color: #1890ff; }
      &.level-B { background: rgba(82, 196, 26, 0.1); color: #52c41a; }
      &.level-C { background: rgba(250, 173, 20, 0.1); color: #faad14; }
      &.level-D { background: rgba(255, 77, 79, 0.1); color: #ff4d4f; }
      &.level-E { background: rgba(0, 0, 0, 0.1); color: #000000; }
    }
  }
}
</style>
